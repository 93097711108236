/* eslint-disable no-await-in-loop */
import React, { FC, useEffect, useState, useMemo } from 'react';

import { Menu, Dropdown, Button, Popconfirm, Space, Alert, Input } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';

import DeviceDetailsModal from 'components/DeviceDetailsModal';
import {
  BarcodeOutlined,
  CalendarOutlined,
  CarOutlined,
  CheckCircleOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  DownOutlined,
  FileOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
  RollbackOutlined,
} from 'components/icons';
import { useDeviceEvents } from 'context/deviceEvents';
import { useDeviceLots } from 'context/deviceLots';
import { IDevice, useDevices } from 'context/devices';
import { useDownloads } from 'context/downloads';
import { usePatients } from 'context/patients';
import { useProjectLaboratories } from 'context/projectLaboratories';
import { IShippingLabel, useShippingLabels } from 'context/shippingLabels';
import useDeviceBulkEditModal from 'hooks/useDeviceBulkEditModal';
import useShippingLabelsModal from 'hooks/useShippingLabelsModal';
import useShippingLabelsV2Modal from 'hooks/useShippingLabelsV2Modal';
import Notification from 'utils/notification';

import { useProjects } from 'context/projects';
import { LABELING_STRATEGIES, USER_ROLES } from 'constants/index';
import { useAuth } from 'context/auth';
import { useOneClickPrint } from 'context/oneClickPrint';
import { downloadShippingLabelDisposition } from 'api/shippingLabels';
import { downloadBarcodeDisposition } from 'api/downloads';
import { useShippingLabelsV2 } from 'context/shippingLabelsV2';
import { Shipment } from '@tassoinc/core-api-client';
import { useShipments } from 'context/shipments';
import { useOrderTemplates } from 'context/orderTemplates';
import { useSites } from 'context/sites';
import AssignDeviceModal from './AssignDeviceModal';
import CreateUnassignedDevicesModal from './CreateUnassignedDevicesModal';
import DeviceList, { TableRow } from './DeviceList';
import EditDeviceModal from './EditDeviceModal';
import CreateBulkOrderModal from './CreateBulkOrderModal';

export interface DevicePagingConfig {
  page: number;
  pageLength: number;
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  includeTotalCount: boolean;
  search: { by: string; term: string } | null;
}

const UnassignedDevices: FC = () => {
  const { projectId }: any = useParams();
  const {
    loadDevices,
    resetDevices,
    devices,
    loading: devicesLoading,
    addDevice,
    deleteDevice,
    updateDevice,
    setLoading,
    modifyDeviceData,
  } = useDevices();
  const {
    projectLaboratories,
    getProjectLaboratories,
    resetProjectLaboratories,
    loading: projectLaboratoriesLoading,
  } = useProjectLaboratories();
  const { isOneClickPrintEnabled } = useOneClickPrint();
  const { profile } = useAuth();
  // ++ handle query string
  const { search } = useLocation();
  const filterByDeviceId: string | undefined = search
    ? new URLSearchParams(search).get('deviceId') || undefined
    : undefined;
  // -- handle query string

  const { patients, getPatients, resetPatients, loading: patientsLoading } = usePatients();

  const { shippingLabels, createShippingLabel, getShippingLabels, cancelShippingLabel, resetLabels } =
    useShippingLabels();
  const { createShippingLabelV2, cancelShippingLabelV2, labels: shippingLabelsV2 } = useShippingLabelsV2();
  const { getShipments, resetShipments } = useShipments();

  const { downloadBarcode, loading: downloadLoading, downloadActivationLabels } = useDownloads();

  const { deviceLots, getDeviceLots, loading: deviceLotsLoading, resetDeviceLots } = useDeviceLots();

  const { deviceEvents, getDeviceEvents, resetDeviceEvents, loading: deviceEventsLoading } = useDeviceEvents();

  const { Modal: DeviceBulkEditModal, showModal: showDeviceBulkEditModal } = useDeviceBulkEditModal();

  const { Modal: ShippingLabelsModal } = useShippingLabelsModal();
  const { Modal: ShippingLabelsV2Modal } = useShippingLabelsV2Modal();

  const { orderTemplates } = useOrderTemplates();

  const { sites } = useSites();

  const [createDevicesModalOpen, setCreateDevicesModalOpen] = useState(false);
  const [addOrdersModalOpen, setAddOrdersModalOpen] = useState(false);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);
  const [editDevice, setEditDevice] = useState<IDevice | null>(null);
  const [detailsDevice, setDetailsDevice] = useState<IDevice | null>(null);
  const [deviceIdToAssign, setDeviceIdToAssign] = useState<string | null>(null);
  const [devicePagingConfig, setDevicePagingConfig] = useState<DevicePagingConfig | null>(null);
  const [totalDeviceCount, setTotalDeviceCount] = useState(0);
  const [deviceIdFilter, setDeviceIdFilter] = useState(filterByDeviceId || '');

  const { project } = useProjects();
  const [prelabeledBarcode, setPrelabeledBarcode] = React.useState('');

  const resetState = () => {
    resetDevices();
    resetProjectLaboratories();
    resetDeviceLots();
    resetPatients();
    resetDeviceEvents();
    resetShipments();
    setSelectedDeviceIds([]);
  };

  useEffect(() => {
    // This is more fiddly than I would like. We want to show shipping label errors when doing prelabeled tubes (where labels are auto generated without modal).
    // So we have this rather specific condition to ensure we are in prelabeled mode and NOT using the "Generate shipping label" button which will show these errors in the modal
    if (
      selectedDeviceIds.length === 0 &&
      project?.labelIdentifierSource === 'barcodePrelabeled' &&
      shippingLabels.length === 1 &&
      shippingLabels[0] &&
      shippingLabels[0].status &&
      (shippingLabels[0].status === 'warning' || shippingLabels[0].status === 'error')
    ) {
      // TODO - offer the barcode and not the deviceId as part of https://tassoinc.atlassian.net/browse/TD-2487
      Notification({
        type: 'error',
        message: `${shippingLabels[0].deviceId} unable to create shipping label: ${shippingLabels[0].message}`,
        duration: 20,
      });
      resetLabels();
    }
  }, [shippingLabels]);

  useEffect(() => {
    getProjectLaboratories(projectId);
    getDeviceLots();

    return () => {
      resetState();
    };
  }, []);

  const loadDeviceDataset = async (includeTotal?: boolean) => {
    if (!devicePagingConfig) {
      resetState();
      return;
    }

    const includeTotalCount = typeof includeTotal === 'boolean' ? includeTotal : devicePagingConfig.includeTotalCount;

    const isAdmin = profile?.role === USER_ROLES.internalAdmin;
    const isCustomerService = profile?.role === USER_ROLES.internalCustomerService;

    await loadDevices(
      {
        ...devicePagingConfig,
        patientIdNull: true,
        ...(isAdmin || isCustomerService ? {} : { statuses: ['readyToShip'] }),
        projectId,
        ...(deviceIdFilter ? { ids: [deviceIdFilter] } : {}),
        includeTotalCount,
      },
      async (loadedDevices, paging) => {
        if (!loadedDevices) {
          resetState();
          return;
        }

        if (paging && includeTotalCount) {
          setTotalDeviceCount(paging.totalCount);
        }
      }
    );
  };

  /**
   * Add new device with given barcode when label identifier source is prelabeled
   * Also triggers generation and printing of shipping label (to lab)
   * @param barcode
   */
  const handlePrelabeledDeviceSubmit = async (barcode: string): Promise<void> => {
    setLoading(true);
    try {
      const laboratoryId = projectLaboratories.length > 0 ? projectLaboratories[0].laboratoryId : null;

      if (!laboratoryId) {
        Notification({
          type: 'error',
          message: `Project is not associated with a laboratory`,
        });
        return;
      }

      if (barcode.length !== 10) {
        Notification({
          type: 'error',
          message: `Invalid barcode length. Expecting 10 characters.`,
        });
        return;
      }
      try {
        const success = await addDevice(
          { projectId, laboratoryId, status: 'readyToShip', barcode },
          undefined,
          false,
          true,
          false
        );

        if (success) {
          Notification({
            type: 'success',
            message: `${barcode} successfully created`,
            duration: 20,
          });
        }
      } catch (e) {
        Notification({
          type: 'warning',
          message: `Could not add device ${e}`,
        });
      }

      await loadDeviceDataset(true);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Add New Devices.  Used when Project.LabelIdentifierSource is not prelabeled.
   * @param count
   */
  const handleNewDevicesSubmit = async (count: number): Promise<void> => {
    setLoading(true);
    try {
      const laboratoryId = projectLaboratories.length > 0 ? projectLaboratories[0].laboratoryId : null;

      if (!laboratoryId) {
        Notification({
          type: 'error',
          message: `Project is not associated with a laboratory`,
        });
        return;
      }

      let addDeviceSuccess = false;
      for (let i = 0; i < count; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        addDeviceSuccess = await addDevice(
          { projectId, laboratoryId, status: 'inStock' },
          undefined,
          false,
          true,
          false
        );
      }

      await loadDeviceDataset(true);

      if (addDeviceSuccess)
        Notification({
          type: 'success',
          message: `${count} device${count === 1 ? '' : 's'} successfully created`,
        });
    } finally {
      setLoading(false);
    }
  };

  const deleteSingleDevice = async (deviceId: string, notify = true, controlLoading = false): Promise<void> => {
    await deleteDevice(deviceId, false, notify, controlLoading);
  };

  const handleDeleteDevices = async (): Promise<void> => {
    setLoading(true);
    for (let i = 0; i < selectedDeviceIds.length; i += 1) {
      const deviceId = selectedDeviceIds[i];
      await deleteSingleDevice(deviceId, false, true);
    }
    setSelectedDeviceIds([]);
    await loadDeviceDataset(true);

    setLoading(false);
  };

  const handleStatusUpdate = async (status: string): Promise<void> => {
    setLoading(true);
    for (let i = 0; i < selectedDeviceIds.length; i += 1) {
      const deviceId = selectedDeviceIds[i];
      await updateDevice(deviceId, { status });
    }

    await loadDeviceDataset(true);

    setLoading(false);
    Notification({ type: 'success', message: 'Device status updated' });
  };

  const handleBarcodeDownload = async (): Promise<void> => {
    downloadBarcode(selectedDeviceIds, isOneClickPrintEnabled ? downloadBarcodeDisposition : undefined);
  };

  const handleDeviceUpdate = async (deviceId: string, values: Record<string, string | null>): Promise<void> => {
    setLoading(true);

    await updateDevice(deviceId, values);
    await loadDeviceDataset(true);

    setLoading(false);
  };

  const deviceHash = useMemo(
    () => devices.reduce((acc, val, index) => ({ ...acc, [val.id]: index }), {} as Record<string, number>),
    [JSON.stringify(devices)]
  );

  const getShipmentsForDevice = async (device: IDevice): Promise<Shipment[]> => {
    const shipmentCode = device.shipmentCode ?? '';
    if (shipmentCode.length === 0) {
      return [];
    }

    const deviceShipments = await new Promise<Shipment[]>((resolve) => {
      getShipments({ orderIds: device.orderId!, shipmentCodeStartsWith: shipmentCode }, (shipmentMatches) => {
        resolve(shipmentMatches ?? []);
      });
    });
    return deviceShipments;
  };

  const getShipmentsForSite = async (device: IDevice): Promise<Shipment[]> => {
    const shipmentCode = device.shipmentCode ?? '';
    if (shipmentCode.length === 0) {
      return [];
    }

    const shipmentCodeFirstLeg = `${shipmentCode.split('.')[0]}.**`;

    const deviceShipments = await new Promise<Shipment[]>((resolve) => {
      getShipments({ orderIds: device.orderId!, shipmentCodeStartsWith: shipmentCodeFirstLeg }, (shipmentMatches) => {
        resolve(shipmentMatches ?? []);
      });
    });
    return deviceShipments;
  };

  const createShipmentLabel = async (deviceId: string, siteLabel: boolean = false): Promise<void> => {
    const isOrderTemplate = devices[deviceHash[deviceId]].orderTemplateId !== null;
    if (isOrderTemplate) {
      const selectedDevice = devices[deviceHash[deviceId]];
      let deviceShipments: Shipment[];
      if (siteLabel) {
        deviceShipments = await getShipmentsForSite(selectedDevice);
      } else {
        deviceShipments = await getShipmentsForDevice(selectedDevice);
      }

      if (deviceShipments.length > 0) {
        return createShippingLabelV2(
          deviceShipments,
          isOneClickPrintEnabled ? downloadShippingLabelDisposition : undefined
        );
      }
      return Promise.reject('Shipments not found');
    }
    return createShippingLabel(
      deviceId,
      isOneClickPrintEnabled ? downloadShippingLabelDisposition : undefined,
      async (device: IDevice): Promise<void> => {
        if (device) {
          modifyDeviceData(deviceId, device, true);
        }
      }
    );
  };

  const handleCreateShippingLabels = async (): Promise<void> => {
    await Promise.all(selectedDeviceIds.map(async (deviceId) => createShipmentLabel(deviceId)));
    await loadDeviceDataset(true);
  };

  const cancelShippingLabels = async (siteLabel: boolean = false): Promise<void> => {
    // Retrieve label ids by deviceIds in order to cancel by labelId
    const labels = await new Promise<IShippingLabel[]>((resolve) => {
      getShippingLabels(selectedDeviceIds, async (result) => resolve(result));
    });
    const deviceIdToLabelIdMap: { [deviceId: string]: string } = {};

    labels.forEach((label: IShippingLabel) => {
      deviceIdToLabelIdMap[label.deviceId] = label.id;
    });

    let shouldDevicesReload = false;

    await Promise.all(
      selectedDeviceIds.map(async (selectedDeviceId) => {
        const selectedDevice = devices[deviceHash[selectedDeviceId]];
        if (selectedDevice.orderTemplateId) {
          let deviceShipments: Shipment[];
          if (siteLabel) {
            deviceShipments = await getShipmentsForSite(selectedDevice);
          } else {
            deviceShipments = await getShipmentsForDevice(selectedDevice);
          }

          if (deviceShipments.length > 0) {
            await cancelShippingLabelV2(deviceShipments);
            shouldDevicesReload = true;
          }
        } else {
          const labelId = deviceIdToLabelIdMap[selectedDeviceId];
          await cancelShippingLabel(selectedDeviceId, labelId, (cancelledLabelDeviceId: string): void => {
            if (cancelledLabelDeviceId) {
              shouldDevicesReload = true;
            }
          });
        }
      })
    );

    if (shouldDevicesReload) {
      await loadDeviceDataset(true);
    }
  };

  const handleCancelShippingLabels = async (): Promise<void> => cancelShippingLabels();

  const assignDeviceToPatient = async (patientId: string): Promise<void> => {
    const deviceId = deviceIdToAssign!;
    await updateDevice(deviceId, { patientId, status: 'atPatient' }, async () => {
      await loadDeviceDataset(true);
    });
  };

  const isLoading = devicesLoading || projectLaboratoriesLoading || downloadLoading || deviceLotsLoading;

  const deviceLotsHash = useMemo(
    () => deviceLots.reduce((acc, val, index) => ({ ...acc, [val.id]: index }), {} as Record<string, number>),
    [deviceLots]
  );

  const deviceList: TableRow[] = useMemo(
    () =>
      devices.map((d) => ({
        id: d.id,
        status: d.status,
        barcode: d.barcode || '',
        lot: d.deviceLotId && d.deviceLotId in deviceLotsHash ? deviceLots[deviceLotsHash[d.deviceLotId]].key : '',
        expiresAt: d.expiresAt,
        trackingNumberToLab: d.trackingNumberToLab || '',
      })),
    [devices, deviceLotsHash]
  );

  const getActivationLabelsDisposition = () =>
    isOneClickPrintEnabled ? 'attachment;filename=activation_label.pdf' : undefined;

  const onGenerateActivationLabels = (disposition: string | undefined) => {
    downloadActivationLabels(selectedDeviceIds, disposition);
  };

  const hasDevicesWithTrackingNumbers = (): boolean => {
    for (let i = 0; i < selectedDeviceIds.length; i += 1) {
      const device = selectedDeviceIds[i] in deviceHash ? devices[deviceHash[selectedDeviceIds[i]]] : null;
      if (device && device.trackingNumberToLab) {
        return true;
      }
    }
    return false;
  };

  const hasLabelingStrategy = project?.labelingStrategy && project.labelingStrategy !== LABELING_STRATEGIES.none;

  useEffect(() => {
    if (devicePagingConfig) {
      loadDeviceDataset();
    }
  }, [devicePagingConfig]);

  useEffect(() => {
    loadDeviceDataset(true);
  }, [deviceIdFilter]);

  useEffect(() => {
    if (!deviceIdToAssign) {
      return;
    }

    if (patients.length === 0 && !patientsLoading) {
      getPatients(projectId);
    }
  }, [deviceIdToAssign]);

  return (
    <div className="UnassignedDevices">
      {!!filterByDeviceId && (
        <Alert
          showIcon
          style={{ margin: '10px 10px 0 10px', width: 'calc(100% - 20px)' }}
          type="info"
          message={
            <>
              Showing one unassigned device.{' '}
              <Link
                to={`/projects/${projectId}/unassigned-devices`}
                onClick={() => {
                  setDeviceIdFilter('');
                }}
              >
                <Button type="link" size="small">
                  Show all unassigned devices
                </Button>
              </Link>
            </>
          }
        />
      )}
      <Space style={{ padding: '10px', flexWrap: 'wrap' }}>
        {project?.labelIdentifierSource === 'barcodePrelabeled' && (
          <Input
            key="prelabelinput"
            type="text"
            placeholder="Scan Tube"
            disabled={selectedDeviceIds.length !== 0}
            allowClear
            style={{ width: '150px' }}
            autoFocus
            value={prelabeledBarcode}
            onChange={(event) => (!isLoading ? setPrelabeledBarcode(event.target.value.trim()) : undefined)}
            onKeyDown={async (event) => {
              if (event.key === 'Enter') {
                await handlePrelabeledDeviceSubmit(prelabeledBarcode);
                setPrelabeledBarcode('');
              }
            }}
          />
        )}
        {project?.useOrderTemplates === false && project?.labelIdentifierSource !== 'barcodePrelabeled' && (
          <Button icon={<PlusCircleOutlined />} type="default" onClick={() => setCreateDevicesModalOpen(true)}>
            Create devices
          </Button>
        )}
        {project?.useOrderTemplates === true && (
          <Button icon={<PlusCircleOutlined />} type="default" onClick={() => setAddOrdersModalOpen(true)}>
            Add Order
          </Button>
        )}
        <Popconfirm
          disabled={selectedDeviceIds.length === 0}
          title={
            <>
              <strong>Note:</strong> cancel any existing shipping labels manually.
              <br />
              This operation will <em>only</em> delete devices from the system.
              <br />
              <br />
              Delete {selectedDeviceIds.length} device{selectedDeviceIds.length === 1 ? '' : 's'}?
            </>
          }
          okText="Proceed"
          cancelText="Close"
          onConfirm={handleDeleteDevices}
        >
          <Button disabled={selectedDeviceIds.length === 0} icon={<DeleteOutlined />} type="default">
            Delete devices
          </Button>
        </Popconfirm>

        <Dropdown
          disabled={selectedDeviceIds.length === 0}
          trigger={['click']}
          overlay={
            <Menu onClick={(item) => handleStatusUpdate(item.key as string)}>
              <Menu.Item key="inStock" icon={<DatabaseOutlined />} disabled={hasDevicesWithTrackingNumbers()}>
                In stock
              </Menu.Item>
              <Menu.Item key="readyToShip" icon={<CheckCircleOutlined />}>
                Ready to ship
              </Menu.Item>
              <Menu.Item key="awaitingPickup" icon={<CarOutlined />}>
                Awaiting pickup
              </Menu.Item>
            </Menu>
          }
        >
          <Button>
            Change status <DownOutlined />
          </Button>
        </Dropdown>

        {hasLabelingStrategy && (
          <Button
            disabled={selectedDeviceIds.length === 0}
            icon={<BarcodeOutlined />}
            type="default"
            onClick={handleBarcodeDownload}
          >
            Generate barcode labels
          </Button>
        )}

        <Button
          disabled={selectedDeviceIds.length === 0}
          icon={<CalendarOutlined />}
          type="default"
          onClick={() => showDeviceBulkEditModal(selectedDeviceIds)}
        >
          Set lot &amp; expiry
        </Button>

        <Button
          disabled={selectedDeviceIds.length !== 1}
          icon={<PrinterOutlined />}
          type="default"
          onClick={handleCreateShippingLabels}
        >
          Generate shipping labels
        </Button>
        <Popconfirm
          disabled={selectedDeviceIds.length !== 1}
          title="Cancel shipping labels for device?"
          okText="Proceed"
          cancelText="Close"
          onConfirm={handleCancelShippingLabels}
        >
          <Button disabled={selectedDeviceIds.length !== 1} icon={<RollbackOutlined />} type="default">
            Cancel shipping labels
          </Button>
        </Popconfirm>

        {project?.patientExperienceEnabled && (
          <Button
            disabled={selectedDeviceIds.length === 0}
            type="default"
            onClick={() => onGenerateActivationLabels(getActivationLabelsDisposition())}
          >
            <FileOutlined /> Generate activation label
          </Button>
        )}
      </Space>
      <DeviceList
        items={deviceList}
        loading={isLoading}
        selectedRowKeys={selectedDeviceIds}
        onRowSelect={(ids) => setSelectedDeviceIds(ids as string[])}
        onEditClick={(deviceIndex) => setEditDevice(devices[deviceIndex])}
        onAssignClick={(deviceIndex) => setDeviceIdToAssign(devices[deviceIndex].id)}
        onDetailsClick={(deviceIndex) => {
          const device = devices[deviceIndex];
          getDeviceEvents(device.id);
          setDetailsDevice(device);
        }}
        totalDeviceCount={totalDeviceCount}
        devicePagingConfig={devicePagingConfig}
        updatePagingConfig={(c: Partial<DevicePagingConfig>) => setDevicePagingConfig((s) => ({ ...s!, ...c }))}
      />
      <CreateUnassignedDevicesModal
        open={createDevicesModalOpen}
        onClose={() => setCreateDevicesModalOpen(false)}
        onSubmit={handleNewDevicesSubmit}
        loading={devicesLoading}
      />

      <CreateBulkOrderModal
        open={addOrdersModalOpen}
        onClose={() => setAddOrdersModalOpen(false)}
        loading={devicesLoading}
        project={project}
        orderTemplates={orderTemplates}
        sites={sites}
      />

      <EditDeviceModal
        open={editDevice !== null}
        device={editDevice}
        onClose={() => setEditDevice(null)}
        onSubmit={handleDeviceUpdate}
        loading={isLoading}
      />

      <AssignDeviceModal
        open={!!deviceIdToAssign}
        patients={patients}
        onSubmit={(patientId) => assignDeviceToPatient(patientId)}
        onClose={() => setDeviceIdToAssign(null)}
        loading={patientsLoading}
      />

      {DeviceBulkEditModal}
      {shippingLabels.length > 0 && ShippingLabelsModal}
      {shippingLabelsV2.length > 0 && ShippingLabelsV2Modal}

      {detailsDevice !== null && (
        <DeviceDetailsModal
          device={detailsDevice}
          patient={null}
          project={project}
          onCancel={() => {
            setDetailsDevice(null);
            resetDeviceEvents();
          }}
          visible
          lot={
            detailsDevice.deviceLotId && detailsDevice.deviceLotId in deviceLotsHash
              ? deviceLots[deviceLotsHash[detailsDevice.deviceLotId]]
              : null
          }
          events={deviceEventsLoading ? [] : deviceEvents}
          fulfillmentOrder={null} // Unassigned devices do not have fulfillmentOrders
          labOrderEvents={[]} // Unassigned devices do not have labOrders
        />
      )}
    </div>
  );
};

export default UnassignedDevices;
